import { render, staticRenderFns } from "./match-finder.vue?vue&type=template&id=3658be54"
import script from "./match-finder.vue?vue&type=script&lang=js"
export * from "./match-finder.vue?vue&type=script&lang=js"
import style0 from "./match-finder.vue?vue&type=style&index=0&id=3658be54&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/home/code/flirt-frontends/packages/twizie/components/Logo.vue').default,SearchProfile: require('/home/code/flirt-frontends/packages/twizie/components/SearchProfile.vue').default,SignupForm: require('/home/code/flirt-frontends/packages/twizie/components/SignupForm.vue').default})
