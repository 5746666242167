
export default {
  name: 'MatchFinder',
  auth: false,
  layout: 'empty',
  data() {
    return {
      selectedProfiles: [],
      loading: false,
      signupProgress: 0,
      signupComplete: false,
      loadedStep: null,
      choices: [],
      clickedChoice: null,
      foundMatchesTop: [],
      foundMatchesCnt: 0,
      loadingMatchesTimerPassed: true,
      loadingMatchesProgressFull: false,
    };
  },
  computed: {
    steps() {
      if (
        (this.$route.query.v6 && this.$route.query.v6 !== '0') ||
        (this.$route.query.v7 && this.$route.query.v7 !== '0')
      ) {
        return ['appearance', 'race'];
      }
      return ['age', 'appearance', 'race'];
    },
    hideNudity() {
      return !!this.$route.query.nn && this.currentStep === 0;
    },

    progress() {
      return (
        ((this.currentStep + 1) / (this.steps.length + 1) / 2 +
          this.signupProgress / 2) *
        100
      );
    },
    displayChoices() {
      return this.currentStep < this.steps.length;
    },
    displayLoadingMatches() {
      return !this.loadingMatchesTimerPassed || !this.foundMatchesCnt;
    },
    currentStep() {
      return this.selectedProfiles?.length || 0;
    },
    selectedProfileIds() {
      if (!this.$route.hash) {
        return [];
      }
      const ids = this.$route.hash.substr(1).split('-');
      return ids.map((x) => parseInt(x)).filter((x) => x);
    },
  },

  watch: {
    selectedProfileIds() {
      this.loadOptions();
    },
    displayChoices(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.loadingMatchesTimerPassed = false;
        this.loadingMatchesProgressFull = false;
        this.signupProgress = 0;
        setTimeout(() => {
          this.loadingMatchesTimerPassed = true;
        }, 4000);
        setTimeout(() => {
          this.loadingMatchesProgressFull = true;
        }, 500);
      }
    },
    signupComplete() {
      this.selectedProfileIds.forEach((id) => this.like(id));
    },
  },
  beforeMount() {
    this.$store.commit('SET_SIGNUP_FLOW_EMBEDDED_SITE_ENTRY_WARNING', true);
  },
  mounted() {
    this.loadOptions();
  },
  methods: {
    async like(profileId) {
      if (this.$auth.loggedIn) {
        await this.$axios.post('/api/likes.php', {
          profile: profileId,
        });
        this.$store.dispatch('chat/load_likes');
      }
    },
    selectProfile(profile, step) {
      if (!this.loading && this.steps.indexOf(step) === this.currentStep) {
        this.selectedProfiles.push(profile);
        if (this.steps.indexOf(step) === this.steps.length - 1) {
          this.like(profile.id);
        }
        this.$router.push({
          query: this.$route.query,
          hash: this.$route.hash
            ? this.$route.hash + '-' + profile.id
            : profile.id + '',
        });
      }
    },
    async loadOptions() {
      this.loading = true;
      try {
        const [searchResults] = await Promise.all([
          this.$axios.get('/api/match-finder.php', {
            params: {
              hideNudity: this.hideNudity ? 1 : 0,
              steps: this.steps,
              selectedProfileIds: this.selectedProfileIds,
            },
          }),
        ]);

        this.choices = searchResults.data.choices;
        this.selectedProfiles = searchResults.data.selectedProfiles;
        this.loadedStep = this.steps[this.selectedProfiles.length];
        this.foundMatchesTop = searchResults.data.found_matches_top;
        this.foundMatchesCnt = searchResults.data.found_matches_cnt;
      } catch (e) {}
      this.loading = false;
    },
  },
};
